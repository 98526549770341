import {
  BALANCE_TRANSACTION,
  BlotterDensity,
  cancelPendingApprovalWithdrawalRequest,
  CustomerBalanceTransactionStatusEnum,
  useAccordionFilterBuilder,
  useConstant,
  useGetDefaultContextMenuItems,
  usePersistedBlotterTable,
  useSocketClient,
  useWsBlotterTable,
  type Column,
  type CustomerBalanceTransaction,
  type DateRangeFilter,
  type WebsocketRequest,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams } from 'ag-grid-community';
import { compact, isEqual, pick } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTransfersFilter } from '../../../../components/Filters/FilterBuilder';

// TODO these columns are wrong, the fields that is. Need to type correctly so we get alerted
const COLUMNS: Column[] = [
  { type: 'date', field: 'Timestamp', width: 225, sortable: true, sort: '-' },
  { type: 'account', field: 'MarketAccount' },
  { type: 'currency', field: 'Currency', width: 200 },
  { type: 'size', field: 'Quantity', params: { currencyField: 'Currency' }, width: 200 },
  { type: 'transactionType', field: 'TransactionType', width: 150 },
  { type: 'transferStatus', field: 'Status', width: 225 },
  { type: 'text', field: 'ExternalComments', title: { intlKey: 'comments' } },
  {
    type: 'custom',
    field: 'TxHash',
    width: 500,
    params: {
      valueGetter: ({ data }) =>
        data.Status === CustomerBalanceTransactionStatusEnum.Completed ? data.TxHash || 'Not Specified' : '',
    },
  },
];

// TODO once the types above are correct, add the rest of the fields to this array
const SEARCH_KEYS: (keyof CustomerBalanceTransaction)[] = ['Timestamp', 'MarketAccount', 'Currency', 'Status'];

interface UseTransfersBlotterProps {
  maxRows: number;
  tag: string;
}

const DEFAULT_SORT = '-Timestamp';

export const useTransfersBlotter = ({ maxRows, tag }: UseTransfersBlotterProps) => {
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const client = useSocketClient();

  const request: WebsocketRequest = useConstant({
    name: BALANCE_TRANSACTION,
    tag: `${tag}/useTransfersBlotter`,
    sort_by: DEFAULT_SORT,
  });

  const persistedBlotterTable = usePersistedBlotterTable('transfers', {
    columns: COLUMNS,
    sort: DEFAULT_SORT,
  });

  const filteredTrades = useTransfersFilter({ persistedBlotterTable });
  const { initialFilter, blotterTableFilterProps, filterBuilderProps } = filteredTrades;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });

  const blotterTable = useWsBlotterTable<WebsocketRequest, CustomerBalanceTransaction>({
    initialRequest: request,
    columns: persistedBlotterTable.columns,
    initialFilter: onlyServerFilterKeys(initialFilter),
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onFilterChanged: persistedBlotterTable.onFilterChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    rowID: 'TransactionID',

    animateRows: true,
    density: BlotterDensity.Comfortable,
    rowHeight: 40,
    getContextMenuItems: (params: GetContextMenuItemsParams) =>
      compact([...getDefaultContextMenuItems(params), cancelPendingApprovalWithdrawalRequest(params, client)]),
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
    },
  });

  const { onFilterChanged } = blotterTable;

  /**
   * When the configured filter changes tell WSBlotterTable about
   * the server keys of the filter but not any locally evaluated keys as they might break the backend.
   */
  useEffect(() => {
    if (filteredTrades.filter) {
      const serverFilter = { ...onlyServerFilterKeys(filteredTrades.filter) };
      if (!isEqual(blotterTable.filter, serverFilter)) {
        onFilterChanged(serverFilter);
      }
    }
  }, [blotterTable.filter, filteredTrades.filter, onFilterChanged]);

  return useMemo(
    () => ({ blotterTable, blotterTableFilterProps, filterBuilderAccordion }),
    [blotterTable, blotterTableFilterProps, filterBuilderAccordion]
  );
};

function onlyServerFilterKeys(filter: DateRangeFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, ['EndDate', 'StartDate']);
}
