import { useEffect, useState } from 'react';
import { useWLOrgConfigContext } from '../../../providers/WLOrgConfigProvider';
import { POST, request } from '../../../utils/http';

export interface MaxOrderAmountResponse {
  MaxOrderAmount: string;
  Currency: string;
  FeesIncluded: boolean;
}

interface MaxOrderAmountProps {
  MarketAccount?: string;
  Symbol?: string;
  Currency?: string;
  Side?: string;
  Price?: string;
}

export const useMaxOrderQuantity = (maxAmountProps: MaxOrderAmountProps) => {
  const [maxOrderAmountResponse, setMaxOrderAmountResponse] = useState<MaxOrderAmountResponse>();
  const { config } = useWLOrgConfigContext();

  const allowedToRequest = config.useBackendMaxAvailable === true;
  const [requesting, setRequesting] = useState(allowedToRequest);

  useEffect(() => {
    // Check if the feature flag (if any) is enabled
    if (!allowedToRequest) {
      setMaxOrderAmountResponse(undefined);
      return;
    }

    const requiredFields = ['MarketAccount', 'Symbol', 'Currency', 'Side'];

    // If any required fields are missing, don't make the request
    if (requiredFields.some(field => !maxAmountProps[field as keyof MaxOrderAmountProps])) {
      setMaxOrderAmountResponse(undefined);
      return;
    }

    setRequesting(true);
    request<{ data: MaxOrderAmountResponse[] }>(POST, '/v1/max-order-amount', maxAmountProps)
      .then(response => {
        setMaxOrderAmountResponse(response.data[0]);
        setRequesting(false);
      })
      .catch(() => {
        setMaxOrderAmountResponse(undefined);
        setRequesting(false);
      });
  }, [allowedToRequest, maxAmountProps]);

  return { maxOrderAmountResponse, loaded: !requesting };
};
